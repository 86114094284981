export const SEQUENTIAL_CHARS = [
  '012',
  '123',
  '234',
  '345',
  '456',
  '567',
  '678',
  '789',
  '890',
  '987',
  '876',
  '765',
  '654',
  '543',
  '432',
  '321',
  '210',
  'abc',
  'bcd',
  'cde',
  'def',
  'efg',
  'fgh',
  'ghi',
  'hij',
  'ijk',
  'jkl',
  'klm',
  'lmn',
  'mno',
  'nop',
  'opq',
  'pqr',
  'qrs',
  'rst',
  'stu',
  'tuv',
  'uvw',
  'vwx',
  'wxy',
  'xyz',
  'zyx',
  'yxw',
  'xwv',
  'wvu',
  'vut',
  'uts',
  'tsr',
  'srq',
  'rqp',
  'qpo',
  'pon',
  'onm',
  'nml',
  'mlk',
  'lkj',
  'kji',
  'jih',
  'ihg',
  'hgf',
  'gfe',
  'fed',
  'edc',
  'dcb',
  'cba',
]
