import { css } from '@emotion/react'
import { useModal } from 'components/modal/Modal'
import { useAdditionalOfferInformationModal } from 'components/modal/offerModal/AdditionalOfferInformationModal'
import { OfferModal } from 'components/modal/offerModal/OfferModal'
import { toastSuccess } from 'components/Toast'
import { describePromoCodes } from 'logic/promo/describePromoCodes'
import { useState } from 'react'
import { MessageException } from 'utils/error'
import { useEventTopic } from 'utils/hooks'
import { CompleteOfferModalResult } from './CompleteOfferModalResult'

export type OfferModalResult =
  // continue -> the user accepted the offer or it was already claimed -> the flow should continue
  | { type: 'continue' }
  // decline -> the user declined the offer -> the flow should continue without the promo being applied
  | { type: 'decline' }
  // cancel -> the flow should not continue and the user has the option to submit again or remove the promo code
  | { type: 'cancel' }

export const useOfferModal = () => {
  const [code, setCode] = useState('')

  const additionalInfoModal = useAdditionalOfferInformationModal()

  const resultTopic = useEventTopic<OfferModalResult>()

  const [offerModal, showOfferModal, close] = useModal(
    () => (
      <OfferModal
        code={code}
        onAccept={() => resultTopic.emit({ type: 'continue' })}
        onDecline={() => resultTopic.emit({ type: 'decline' })}
      />
    ),
    {
      modalCss: css({ maxWidth: 480 }),
      onClose: () => {
        resultTopic.emit({ type: 'cancel' })
      },
    }
  )

  return {
    jsx: (
      <>
        {offerModal}
        {additionalInfoModal.jsx}
      </>
    ),
    open: async (
      code: string,
      config?: {
        noAlreadyClaimedInfoMessage?: boolean
        noAskAdditionalInformation?: boolean
        autoAccept?: boolean
      }
    ): Promise<CompleteOfferModalResult> => {
      const promo = await describePromoCodes(code).getExtendedPromotion()

      if (promo.isBeforeValidRange) {
        throw new MessageException('before_valid_error')
      }

      if (promo.cannotClaimReason === 'reached_max_users') {
        throw new MessageException('reached_max_users_error')
      }

      if (promo.cannotClaimReason === 'promo_code_usage_exceeded') {
        throw new MessageException('promo_code_usage_exceeded')
      }

      // Code is expired -> Show error toast
      const isExpired = promo.claimedPromo
        ? promo.isExpiredForRedeeming
        : promo.isExpiredForClaiming
      if (isExpired) {
        throw new MessageException('already_expired_error')
      }

      // Code has no redeemable discounts left -> Show error toast
      // We don't want this to happen when claiming for the first time, so we only
      // run this if claimedPromo is not nullish, which means it has already been claimed before
      if (promo.claimedPromo && promo.offers.every((offer) => offer.isFullyRedeemed)) {
        throw new MessageException('no_redeemable_discounts_left_error')
      }

      // The user already claimed the promo code before.
      // We don't show a modal in this case, we just continue the flow.
      if (promo.claimedPromo) {
        if (!config?.noAlreadyClaimedInfoMessage) {
          toastSuccess('already_claimed_before_info_message')
        }
        return {
          type: 'continue',
          promo,
        }
      }

      const codeRequiresAdditionalInformation = await describePromoCodes(
        code
      ).doesPromoRedeemingRequireAdditionalInformation()

      if (config?.autoAccept && !codeRequiresAdditionalInformation) {
        return { type: 'continue', promo }
      }
      setCode(code)

      let result: { type: 'continue' | 'decline' | 'cancel' }
      if (!config?.autoAccept) {
        showOfferModal()
        result = await resultTopic.once()
      } else {
        result = { type: 'continue' }
      }
      const needAdditionalInformation =
        result.type === 'continue' && codeRequiresAdditionalInformation

      const info =
        needAdditionalInformation && !config?.noAskAdditionalInformation
          ? await additionalInfoModal.open(code)
          : undefined

      close()

      if (info === 'close') {
        return {
          type: 'cancel',
          promo,
        }
      }

      return { ...result, promo, info }
    },
  }
}
