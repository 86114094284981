import { getDirectiveSpec } from 'components/directive/utils/directiveSpecs'
import { describeDirective } from 'logic/directive/describe'
import { getUrlPathFromEnumValue } from 'utils/format'
import { logError } from 'utils/general'
import { ApiType } from 'utils/types'

export function getDirectiveEditLink(
  directive: Partial<ApiType['UserDirectiveResponse']>,
  userMe: ApiType['UserResponse']
) {
  const { uuid, directive_type, last_section } = directive
  if (!directive_type) {
    logError('Unknown directive type')
    return ''
  }

  const desc = describeDirective(directive_type)
  const flow = desc.pickFlow({ isLoggedIn: true, userMe })

  if (
    flow.type === 'simple' &&
    (last_section == null || desc.isSectionPartOfSimpleFlow(last_section))
  ) {
    const nextSection = last_section ? flow.getNextSection(last_section) : flow.getInitialSection()
    return flow.withDirective(directive).getSectionUrl(nextSection)
  }

  const nextSection =
    last_section === 'MORE_ABOUT_YOU'
      ? 'ABOUT_MORE'
      : last_section != null && desc.isSectionPartOfSimpleFlow(last_section)
      ? 'YOUR_FAMILY'
      : last_section ?? flow.getInitialSection()

  const { insidePath } = getDirectiveSpec(directive_type)

  return `${insidePath}/${uuid}/steps/${getUrlPathFromEnumValue(nextSection)}`
}

export function getDirectiveDoneLink(
  uuid: string | undefined,
  type: ApiType['UserDirectiveRequest']['directive_type'] | undefined
) {
  if (!type) {
    logError('Unknown directive type')
    return ''
  }

  const { insidePath } = getDirectiveSpec(type)

  return `${insidePath}/${uuid}`
}
