import { OfferSpec, OfferType } from 'utils/promo/types'

export const offerSpecs: Record<OfferType, OfferSpec> = {
  DISCOUNT: {},
  ANIMATION_PACK_FREE: {},
  STORY_PLUS_FREE: {
    freeSubscription: 'STORY_PLUS',
  },
  ANIMATION_SUBSCRIPTION_FREE: {
    freeSubscription: 'ANIMATION_SUBSCRIPTION',
  },
  DIGITAL_SAFETY_FREE: {
    freeSubscription: 'DIGITAL_SAFETY',
  },
  ESTATE_PLAN_FREE: {
    freeSubscription: 'ESTATE_PLANNING',
  },
  ULTIMATE_PLAN_FREE: {
    freeSubscription: 'ULTIMATE_PLAN',
  },
}
