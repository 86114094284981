import { MedicalStepKey } from 'components/directive/medical/types'
import { isValidationErrorRelatedToAboutYou } from 'components/directive/utils/isValidationErrorRelatedToAboutYou'
import { DirectiveSectionSpecification } from 'components/directive/utils/types'

export const MEDICAL_DIRECTIVE_PROGRESS: DirectiveSectionSpecification<MedicalStepKey>[] = [
  {
    name: 'directive.medical.about_you.nav_title',
    mobile: 'directive.medical.about_you.nav_title_short',
    value: 'ABOUT_YOU',
    url: 'about-you',
    subsections: [
      {
        sectionKey: 'ABOUT_YOU',
        isValidationErrorRelated: isValidationErrorRelatedToAboutYou,
      },
      {
        sectionKey: 'ABOUT_MORE',
      },
    ],
  },
  {
    name: 'directive.medical.form.physician.nav_title',
    mobile: 'directive.medical.form.physician.nav_title_short',
    value: 'PHYSICIAN',
    url: 'physician',
  },
  {
    name: 'directive.medical.form.health_agent.nav_title',
    mobile: 'directive.medical.form.health_agent.nav_title_short',
    value: 'HEALTH_AGENT',
    url: 'health-agent',
  },
  {
    name: 'directive.medical.form.wishes.nav_title',
    mobile: 'directive.medical.form.wishes.nav_title_short',
    value: 'WISHES',
    url: 'wishes',
  },
  {
    name: 'directive.medical.form.signature.nav_title',
    mobile: 'directive.medical.form.signature.nav_title_short',
    value: 'SIGNATURE',
    url: 'signature',
  },
  {
    name: 'directive.medical.review_nav_title',
    value: 'REVIEW',
    url: 'review',
  },
]
