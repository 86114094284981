import { apiAuthFetcher, apiFetcher } from 'api/goodtrust/api'
import { getPromotions, postPromocodeClaim, postPromoCodeValidate } from 'api/goodtrust/promo'
import { toastSuccess } from 'components/Toast'
import { describePromotion } from 'logic/promo/describe'
import { describePromoOffer } from 'logic/promo/describePromoOffer'
import { AdditionalRedeemInfo, HandleClaimPromoCodeResult } from 'logic/promo/type'
import { mutate } from 'swr'
import { isUserLoggedIn } from 'utils/auth/authFetcher'
import { bound } from 'utils/class'
import { unwrapResponse } from 'utils/fetcher'
import { extendPromotion } from 'utils/promo'
import { mutateKeys } from 'utils/swr'
import { getUserMe } from 'api/goodtrust/user'
import { ShouldNeverHappenError } from 'utils/error'

export function describePromoCodes(code: string) {
  return bound({
    async doesPromoRedeemingRequireAdditionalInformation() {
      const promo = await this.getExtendedPromotion()
      return promo.freeSubOffers.some(
        (offer) =>
          offer.type && describePromoOffer(offer).doesOfferRedeemingRequireAdditionalInformation()
      )
    },
    async handleClaimPromoCode(info: AdditionalRedeemInfo | undefined, email?: string) {
      const promo = await this.getExtendedPromotion()
      const promotionDescription = describePromotion(promo.promo_code_info ?? {})
      const result = await this.claimPromoIfNotClaimed(email)
      const nowRedeemedSubscriptionOffers: HandleClaimPromoCodeResult['nowRedeemedSubscriptionOffers'] =
        []

      for (const offer of promo.freeSubOffers) {
        if (promotionDescription.canRedeemSubscriptionOffer(offer)) {
          const offerDescription = describePromoOffer(offer)
          const result = await offerDescription.redeemFreeSubscriptionOffer(code, info, email)
          if (result === 'redeemed') nowRedeemedSubscriptionOffers.push(offer)
        }
      }

      const promoAfterClaiming = await this.getExtendedPromotion()

      if (result === 'claimed') {
        toastSuccess('promo_code_claimed')
      }

      await mutate('/api/v1/user/me')
      mutateKeys((key) => {
        return typeof key === 'string' && key.includes('/api/v1/promocode')
      })

      return { ...promoAfterClaiming, nowRedeemedSubscriptionOffers }
    },
    async claimPromoIfNotClaimed(email?: string) {
      let userMe = undefined
      try {
        userMe = await getUserMe()
      } catch (e) {
        console.log(e, 'here e')
      }
      const promotions = userMe?.ok
        ? await getPromotions().then(unwrapResponse.body)
        : { promo_codes: [] }

      const isAlreadyClaimed = promotions.promo_codes?.some((promo) => promo.code === code)
      if (isAlreadyClaimed) return 'already-claimed'

      if (email != null) {
        await postPromocodeClaim({ code, email: email }).then(unwrapResponse)
      } else if (userMe?.json?.email != null) {
        await postPromocodeClaim({ code, email: userMe.json.email }).then(unwrapResponse)
      } else {
        throw new ShouldNeverHappenError()
      }
      return 'claimed'
    },
    async getExtendedPromotion() {
      const isLoggedIn = await isUserLoggedIn()
      const claimedPromotions = isLoggedIn
        ? await getPromotions().then(unwrapResponse.body)
        : undefined

      return await postPromoCodeValidate(code, undefined, isLoggedIn ? apiAuthFetcher : apiFetcher)
        .then(unwrapResponse.body)
        .then((promo) => extendPromotion(promo, claimedPromotions ?? {}))
    },
  })
}
