import { ApiType } from 'utils/types'
import { apiAuthFetcher, apiFetcher } from './api'
import { hashPassword } from 'utils/auth/password'
import Router from 'next/router'

export async function postRefresh(refreshToken: string) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/auth/refresh', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  })
}

export async function postLogout(refreshToken: string) {
  return apiFetcher<void>('/api/v1/auth/logout', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  })
}

export async function postLogin(email: string, password: string) {
  const url = {
    pathname: Router.pathname,
    query: { ...Router.query, email },
  }
  await Router.push(url, undefined, {
    shallow: true,
  })
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/auth/login', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(`${email}:${await hashPassword(password)}`)}`,
    },
  })
}

export async function resendMfaLoginCode(mfaToken: string) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/user/mfa/resend', mfaToken, {
    method: 'POST',
    body: {
      old_mfa_token: mfaToken,
    } as ApiType['ResendMfaCodeCommand'],
  })
}

export async function postMfaLogin(code: string, mfaToken: string) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/auth/login/mfa', mfaToken, {
    method: 'POST',
    body: { code },
  })
}

export async function postRegister(email: string, password: string) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/auth/login', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(`${email}:${await hashPassword(password)}`)}`,
    },
  })
}

export async function postUserVerification(body: ApiType['UserVerifyRequest']) {
  return apiAuthFetcher<ApiType['VerificationTokenResponse']>('/api/v1/auth/verify', {
    method: 'POST',
    body,
  })
}

export function postAuthTokenCheck(body: ApiType['CheckSignupTokenCommand']) {
  return apiFetcher<ApiType['SignupTokenCheckResponse']>(`/api/v1/auth/signup-token/check`, {
    method: 'POST',
    body,
  })
}

export function postAuthTokenProcess(body: ApiType['ProcessSignupTokenCommand']) {
  return apiFetcher<ApiType['SignupTokenCheckResponse']>(`/api/v1/auth/signup-token`, {
    method: 'POST',
    body,
  })
}
